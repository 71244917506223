import React from 'react';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import Divider from './Divider';

import { red, deepOrange, amber, lightGreen, cyan, blue, purple } from '@mui/material/colors';
// import { CodeBlock, dracula, monokai } from "react-code-blocks";
import hljs from 'highlight.js/lib/core';
import Box from '@mui/material/Box';
// import hljs from 'highlight.js';
import 'highlight.js/styles/docco.css';
// import penmanLanguage from './penmanLanguage';
import PenmanHighlight from './PenmanHighlight';

const colors = [red[500], deepOrange[500], amber[400], lightGreen[400], cyan[400], blue[400], purple[300]];
// SyntaxHighlighter.registerLanguage('penman', penmanLanguage);
// hljs.registerLanguage('penman', penmanLanguage);



const DocAMRPanel = ({sentAMRs, setSentAMRs, codeOnly, allClusters, setAllClusters, uploadAMRCorefs, selectedCluster, alignedTokens, setAlignedTokens, amrBoxRefs}) => {
 
  return (
    <div style={{ padding: 5, overflow: 'auto', height: '100%' }}>
    { sentAMRs.map((sntAMRtokens,index) => {
        if (sntAMRtokens) {
          return (
            <div key={index}>
              <Divider spacing={1}>sentence-{index+1} &#8595;</Divider>
              <Box 
                ref={(el) => (amrBoxRefs.current[index] = el)}
              >
                <PenmanHighlight 
                  sntAMRtokens={sentAMRs}
                  setSentAMRS={setSentAMRs}
                  codeOnly={codeOnly}
                  sentID={index}
                  allClusters={allClusters}
                  setAllClusters={setAllClusters}
                  uploadAMRCorefs={uploadAMRCorefs}
                  selectedCluster={selectedCluster}
                  alignedTokens={alignedTokens}
                  setAlignedTokens={setAlignedTokens}
                />
              </Box>
            </div>
          )
         }
         return null
    }) }
    </div>
  );
}

export default DocAMRPanel